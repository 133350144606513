<script>
export default {
	props: {
		dark: Boolean,
	},
}
</script>

<template>
	<router-link :to="{ name: 'home' }">
		<Media
			:src="$srv('SiteLogo.logoNavbar')"
			:width="$vuetify.breakpoint.xs ? '150px' : '200px'"
			:style="{ filter: dark ? 'invert(100%)' : 'none' }"
		/>
	</router-link>
</template>
