var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h100vh relative w100 black"},[_vm._l((_vm.data),function(ref,i){
var image = ref.image;
return _c('div',{key:i,staticClass:"image-background overflow-hidden",staticStyle:{"width":"100%","height":"100vh"}},[_c('transition',{attrs:{"name":"slide-fade","appear":""}},[(_vm.currentIndex === i)?_c('div',{staticClass:"zoom-in"},[_c('Media',{attrs:{"src":_vm.getImageSrc(image),"height":"100vh","width":"100%","cover":""}})],1):_vm._e()])],1)}),_c('div',{staticClass:"overlay",style:(("background-color: rgba(0,0,0," + _vm.opacity + ")"))},[_c('div',{staticClass:"btn-prev d-none d-sm-block"},[_c('v-btn',{attrs:{"fab":"","dark":"","text":""},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-chevron-left")])],1)],1),_c('div',{staticClass:"btn-next d-none d-sm-block justispa"},[_c('v-btn',{attrs:{"fab":"","dark":"","text":""},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-chevron-right")])],1)],1),_c('div',{staticClass:"h100 w100 d-flex justify-center align-center px-4"},_vm._l((_vm.data),function(ref,i){
var subtitle = ref.subtitle;
var title = ref.title;
var buttonText = ref.buttonText;
var buttonUrl = ref.buttonUrl;
return _c('div',{key:i,staticClass:"white--text"},[(_vm.currentIndex === i)?_c('div',{staticClass:"d-flex flex-column align-center w100 pa-2"},[_c('div',{staticClass:"mb-4 first-text-anim text-center"},[_vm._v(_vm._s(subtitle))]),(_vm.currentIndex === i)?_c('h2',{staticClass:"font-5 font-md-9 max-width-80 ff-2-regular font-weight-bold text-center mb-4 second-text-anim"},[_vm._v(" "+_vm._s(title)+" ")]):_vm._e(),_c('SafeLink',{attrs:{"to":buttonUrl}},[(_vm.currentIndex === i)?_c('WButton',{staticClass:"third-text-anim",attrs:{"x-large":""}},[_vm._v(" "+_vm._s(buttonText)+" ")]):_vm._e()],1)],1):_vm._e()])}),0)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }