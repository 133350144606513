import { render, staticRenderFns } from "./comp_Home-C-OurProducts.vue?vue&type=template&id=47c8f8b3&scoped=true&"
import script from "./comp_Home-C-OurProducts.vue?vue&type=script&lang=js&"
export * from "./comp_Home-C-OurProducts.vue?vue&type=script&lang=js&"
import style0 from "./comp_Home-C-OurProducts.vue?vue&type=style&index=0&id=47c8f8b3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47c8f8b3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
