<template>
	<div class="border t1 text-center py-3 text-uppercase" :style="customStyle">
		{{ title }}
	</div>
</template>

<script>
export default {
	props: {
		title: String,
		customStyle: String,
	},
}
</script>

<style>
.border {
	border-top: 1px solid #808080;
	border-bottom: 1px solid #808080;
}
</style>
