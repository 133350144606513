<script>
export default {
	data() {
		return {}
	},
	props: {
		product: Object,
		listId: String,
		listIndex: Number,
	},
	computed: {
		timeText() {
			let days = this.checkText(this.offerTime.days, 'dia')
			let hours = this.checkText(this.offerTime.hours, 'hora')
			let minutes = this.checkText(this.offerTime.minutes, 'minuto')
			let value = () => {
				if (this.offerIsfinished) return `Oferta finalizada`
				if (this.offerTime.days > 0) return `Queda ${days} - ${hours}`
				if (this.offerTime.days < 1 && this.offerTime.hours > 0) return `Queda ${hours} - ${minutes}`
				if (this.offerTime.hours < 1) return `Queda ${minutes}`
			}
			return value()
		},
		productRoute() {
			return {
				name: 'product',
				params: {
					urlName: this.product.urlName,
					id: this.product.id,
				},
			}
		},
		mainVariant() {
			return this.product.variants.find((variant) => variant.main) || this.product.variants[0]
		},
		prevPrice() {
			return this.mainVariant.pvPrice.prevPrice
		},
		price() {
			return this.mainVariant.pvPrice.price
		},
		discountPct() {
			return this.mainVariant.pvPrice.discountPct
		},
		mainImage() {
			return this.product.images[0].square
		},
		secondImage() {
			return this.product.images[1]?.square
		},
		currency() {
			return this.mainVariant.pvPrice.priceConfig.currency.sign
		},
	},
	methods: {
		checkText(value, text) {
			if (value > 1) {
				return `${value} ${text}s`
			} else {
				return `${value} ${text}`
			}
		},
		async cardClick() {
			this.$eventer.trigger('product:click', {
				product: this.product,
				variant: this.mainVariant,
				listId: this.listId,
				index: this.listIndex,
			})
			this.$router.push(this.productRoute)
		},
	},
	created() {
		if (this.hasTimeLimit) {
			let hasTimeLimit = this.hasTimeLimit
			let timeText = this.timeText
			localStorage.setItem(
				this.product.id,
				JSON.stringify({
					hasTimeLimit,
					timeText,
				})
			)
		}
	},
}
</script>

<template>
	<v-card
		@click="cardClick"
		width="100%"
		height="100%"
		elevation="5"
		color="white"
		class="rounded-0 d-flex align-center justify-space-between py-0 pr-3"
	>
		<slot name="images" v-bind="{ mainImage, secondImage }">
			<ProductCardImage :mainImage="mainImage" :secondImage="secondImage" style="min-width: 40%" />
		</slot>
		<div class="d-flex flex-column align-center justify-space-between flex-grow-1 h100">
			<div class="abs-right">
				<ProductWishlistToggler :productId="product.id" small />
			</div>
			<div class="mt-6">
				<div class="gold--text text-uppercase t3 pt-1 pb-3 text-center">
					{{ product.name }}
				</div>
			</div>
			<div class="d-flex flex-column align-center">
				<div class="d-flex align-center justify-center">
					<ProductPrevPrice
						v-if="prevPrice && prevPrice > price"
						:amount="prevPrice"
						custom-class="grey--text mr-2"
						:currency="currency"
					/>
					<ProductPrice :amount="price" custom-class="gold--text s5" :currency="currency" />
				</div>
				<ProductDiscount
					v-if="discountPct"
					:amount="discountPct"
					custom-class="gold white--text font-weight-bold pa-2 font-0 abs-left"
				/>

				<v-card-actions class="d-flex flex-column flex-sm-row">
					<ProductActions :product="product" :variant="mainVariant" cta-text="Agregar" />
					<span class="grey--text font-weight-bold t3 ml-4 my-4 d-flex align-center">
						<div>Ver</div>
						<v-icon>mdi-chevron-right</v-icon>
					</span>
				</v-card-actions>
			</div>
		</div>
	</v-card>
</template>
<style scoped>
.abs-right {
	position: absolute;
	top: 0px;
	right: 10px;
	z-index: 1;
}
.abs-left {
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 1;
}
.text-color {
	color: rgb(156, 156, 156);
}
</style>
