<script>
export default {
	data: () => ({ currentIndex: 0, interval: null }),
	computed: {
		data() {
			return this.$srv('Home_A_Header_2')
		},
		opacity() {
			const { opacity } = this.data[this.currentIndex]
			return opacity ? opacity / 100 : 0.5
		},
	},
	mounted() {
		this.interval = setInterval(() => this.move(1), 8000)
	},
	methods: {
		next() {
			this.move(1, true)
		},
		prev() {
			this.move(-1, true)
		},
		move(by, userInteraction = false) {
			let currentIndex = this.currentIndex + by
			if (currentIndex > this.data.length - 1) {
				currentIndex = 0
			}
			if (currentIndex < 0) {
				currentIndex = this.data.length - 1
			}
			if (userInteraction && this.interval) {
				clearInterval(this.interval)
				this.interval = null
			}
			this.currentIndex = currentIndex
		},
		getImageSrc(image) {
			if (!image._multiple) return image
			const { breakpoint } = this.$vuetify
			const isXsOrSm = breakpoint.name === 'xs' || breakpoint.name === 'sm'
			return image[isXsOrSm ? 'md' : breakpoint.name]
		},
	},
	beforeDestroy() {
		if (this.interval) {
			clearInterval(this.interval)
		}
	},
}
</script>
<template>
	<div class="h100vh relative w100 black">
		<div
			style="width: 100%; height: 100vh"
			v-for="({ image }, i) of data"
			:key="i"
			class="image-background overflow-hidden"
		>
			<transition name="slide-fade" appear>
				<div v-if="currentIndex === i" class="zoom-in">
					<Media :src="getImageSrc(image)" height="100vh" width="100%" cover />
				</div>
			</transition>
		</div>
		<div class="overlay" :style="`background-color: rgba(0,0,0,${opacity})`">
			<div class="btn-prev d-none d-sm-block">
				<v-btn @click="prev" fab dark text>
					<v-icon x-large>mdi-chevron-left</v-icon>
				</v-btn>
			</div>
			<div class="btn-next d-none d-sm-block justispa">
				<v-btn @click="next" fab dark text>
					<v-icon x-large>mdi-chevron-right</v-icon>
				</v-btn>
			</div>
			<div class="h100 w100 d-flex justify-center align-center px-4">
				<div v-for="({ subtitle, title, buttonText, buttonUrl }, i) of data" class="white--text" :key="i">
					<div class="d-flex flex-column align-center w100 pa-2" v-if="currentIndex === i">
						<div class="mb-4 first-text-anim text-center">{{ subtitle }}</div>
						<h2
							class="font-5 font-md-9 max-width-80 ff-2-regular font-weight-bold text-center mb-4 second-text-anim"
							v-if="currentIndex === i"
						>
							{{ title }}
						</h2>
						<SafeLink :to="buttonUrl">
							<WButton x-large v-if="currentIndex === i" class="third-text-anim">
								{{ buttonText }}
							</WButton>
						</SafeLink>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.relative {
	position: relative;
}
.h100vh {
	height: 100vh;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
	transition: all 2s;
}
.slide-fade-enter,
.slide-fade-leave-to {
	opacity: 0;
}

.image-background {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	transition: all 2s;
}
.zoom-in {
	animation: zoomIn 12s forwards;
}

.btn-next,
.btn-prev {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.btn-next {
	right: 30px;
}
.btn-prev {
	left: 30px;
}
.max-width-80 {
	max-width: 80%;
}
@keyframes zoomIn {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.1);
	}
}
@keyframes fromBottom {
	0% {
		transform: translateY(30px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
.first-text-anim {
	opacity: 0;
	animation: fromBottom 0.6s forwards;
	animation-delay: 1s;
}
.second-text-anim {
	opacity: 0;
	animation: fromBottom 0.6s forwards;
	animation-delay: 1.4s;
}
.third-text-anim {
	opacity: 0;
	animation: fromBottom 0.6s forwards;
	animation-delay: 1.8s;
}
</style>
