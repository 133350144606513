<script>
export default {
	computed: {
		data() {
			return this.$srv('Home_B_Featured')
		},
	},
	mounted() {
		if (this.data?.products?.length) {
			this.$eventer.trigger('product-list:view', {
				products: this.data.products,
				listId: 'home-b-featured',
			})
		}
	},
}
</script>

<template>
	<section v-if="data && data.products.length" class="coral w100">
		<SectionTitle :title="data.title" />
		<Container width="100%" max-width="1200">
			<Carousel
				class="product-card-carousel w100"
				:items="data.products"
				:options="{
					itemsPadding: $vuetify.breakpoint.smAndDown ? 3 : 8,
					panelsPerView: { xs: 1.5, sm: 3, md: 3 },
					arrows: { md: true },
					arrowsMargin: 30,
					itemWrapperClass: 'py-10',
				}"
				:flicking-options="{
					align: 'prev',
					bound: true,
					moveType: { xs: 'freeScroll', md: 'snap' },
				}"
			>
				<template #panel="{ item, i }">
					<HomeProductCard :product="item" :list-index="i" list-id="home-b-featured" />
				</template>
			</Carousel>
		</Container>
	</section>
</template>
