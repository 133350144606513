import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const vuetify = new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#CB8C1A',
				secondary: '#efb650',
				darkgreen: '#1A3B34',
				gold: '#CB8C1A',
				coral: '#FFF8EC',
				black: '#000000',
				gray: '#808080',
				lightgray: '#FAFAFA',
				darkgray: '#1A3B34',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#1A3B34',
				warning: '#FFC107',
				link: colors.blue.darken4,
			},
		},
	},
})

export default { vuetify }
