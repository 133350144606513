import { TermsCondition, Info, Privacy } from '@/site/autoloader'

export default [
	{
		path: '/terminos-y-condiciones',
		name: 'terms',
		component: TermsCondition,
	},
	{
		path: '/cambios-y-devoluciones',
		name: 'Info',
		component: Info,
	},
	{
		path: '/privacidad',
		name: 'Privacy',
		component: Privacy,
	},
]
