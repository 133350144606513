<script>
export default {
	props: {
		product: Object,
		listId: String,
		listIndex: Number,
	},
	computed: {
		productRoute() {
			return {
				name: 'product',
				params: { urlName: this.product.urlName, id: this.product.id },
			}
		},
		mainVariant() {
			return this.product.variants.find((variant) => variant.main) || this.product.variants[0]
		},
		prevPrice() {
			return this.mainVariant.pvPrice.prevPrice
		},
		price() {
			return this.mainVariant.pvPrice.price
		},
		discountPct() {
			return this.mainVariant.pvPrice.discountPct
		},
		mainImage() {
			return this.product.images[0].square
		},
		secondImage() {
			return this.product.images[1]?.square
		},
		currency() {
			return this.mainVariant.pvPrice.priceConfig.currency.sign
		},
	},
	methods: {
		async cardClick() {
			this.$eventer.trigger('product:click', {
				product: this.product,
				variant: this.mainVariant,
				listId: this.listId,
				index: this.listIndex,
			})
			this.$router.push(this.productRoute)
		},
	},
}
</script>

<template>
	<v-card hover flat tile @click="cardClick" color="transparent">
		<slot name="images" v-bind="{ mainImage, secondImage }">
			<Media :src="mainImage" width="100%" />
		</slot>
		<div
			class="black--text text-uppercase font-weight-bold t4 pt-3 pb-2"
			style="border-bottom: 1px solid var(--v-gray-base)"
		>
			<div class="line-clamp-2" style="height: 40px">
				{{ product.name }}
			</div>
		</div>
		<div class="py-2 d-flex justify-end">
			<PriceLayout
				:prevPrice="prevPrice"
				:price="price"
				:discountPct="discountPct"
				:currency="currency"
				price-class="black--text t3 text-end pr-1"
				discount-class="gold white--text font-weight-bold pa-2"
				prev-price-class="mr-3 grey--text font-weight-bold"
				asboluteDiscount
				class="d-flex"
			/>
		</div>
	</v-card>
</template>
