<script>
import { get } from 'vuex-pathify'
export default {
	computed: {
		data() {
			return this.$srv('Home_D_TheWhiskery')
		},
		bottom() {
			const { xs, sm, md } = this.$vuetify.breakpoint
			if (xs) return '-110px'
			if (sm) return '-80px'
			if (md) return '-50px'
			else return '-30px'
		},
		isMember: get('shop/user@member'),
	},
}
</script>

<template>
	<section v-if="data">
		<SectionTitle :title="data.title" />
		<div class="w100 pa-0 gold relative">
			<Media
				:src="data.first_image"
				:width="$vuetify.breakpoint.xs ? '100%' : '50%'"
				max-height="500px"
				class="d-inline-block"
				cover
			/>
			<Media
				:src="data.second_image"
				width="50%"
				class="d-none d-sm-inline-block"
				max-height="500px"
				cover
			/>
			<Media :style="{ bottom }" :src="data.logo" max-width="90vw" width="650" height="220" class="logo" />
		</div>
		<div class="gold py-12 text-center">
			<Container max-width="1000px" width="100%" class="pt-14">
				<div class="s1 mb-8">{{ data.subtitle }}</div>
				<div class="mb-8">{{ data.description }}</div>
				<div class="d-flex justify-center">
					<v-btn
						large
						class="rounded-0 elevation-0 btn-with-line"
						color="black"
						outlined
						:to="{ name: 'thewhiskery' }"
					>
						{{ data.buttonTextMember && isMember ? data.buttonTextMember : data.buttonText }}
					</v-btn>
				</div>
			</Container>
		</div>
	</section>
</template>

<style scoped lang="scss">
.logo {
	position: absolute !important;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;
	// bottom: -110px;
	// @media screen and (min-width: 1264px) {
	// 	bottom: -30px;
	// }
	// @media screen and (min-width: 1264px) {
	// 	bottom: -30px;
	// }
}
</style>
