// Pathify
import { make } from 'vuex-pathify'

// Data

const state = {
	dateSaved: Boolean(window.localStorage.getItem('dateSaved')),
	dialogClosed: Boolean(window.localStorage.getItem('dialogClosed')),
	endedForm: Boolean(window.localStorage.getItem('endedForm')),
	dontShowVideo: Boolean(window.localStorage.getItem('dontShowVideo')),
	memberDialog: false,
	twForm: false,
	dontShowMemberDialog: Boolean(window.localStorage.getItem('DONT_SHOW_MEMBER_DIALOG')),
}

const mutations = make.mutations(state)

const actions = {
	setDateSaved({ commit }) {
		window.localStorage.setItem('dateSaved', true)
		commit('SET_DATE_SAVED', true)
	},
	setDialogClosed({ commit }) {
		window.localStorage.setItem('dialogClosed', true)
		commit('SET_DIALOG_CLOSED', true)
	},
	setEndedForm({ commit }) {
		window.localStorage.setItem('endedForm', true)
		commit('SET_ENDED_FORM', true)
	},
	setDontShowVideo({ commit }) {
		window.localStorage.setItem('dontShowVideo', true)
		commit('SET_DONT_SHOW_VIDEO', true)
	},
	setTwForm({ commit, state }) {
		commit('SET_TW_FORM', !state.twForm)
	},
	setMemberDialog({ commit }, v) {
		commit('SET_MEMBER_DIALOG', v)
	},
	setDontShowMemberDialog({ commit }, v) {
		window.localStorage.setItem('DONT_SHOW_MEMBER_DIALOG', true)
		commit('SET_DONT_SHOW_MEMBER_DIALOG', v)
	},
}

const getters = {}

export default {
	name: 'whisky',
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
