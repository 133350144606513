<script>
import { get, sync } from 'vuex-pathify'

export default {
	name: 'MobileMenuDrawer',
	computed: {
		menuMobileDrawer: sync('shop/menuMobileDrawer'),
		user: get('shop/user'),
		links() {
			return this.$srv('NavbarLinks').map((info) => {
				if (info.memberText && !!this.user?.member)
					return {
						text: info.memberText,
						url: info.url,
					}
				else return info
			})
		},
	},
	methods: {
		showLoginDrawer() {
			this.menuMobileDrawer = false
			this.$nextTick(() => {
				this.$store.set('shop/loginDrawer', true)
			})
		},
	},
}
</script>

<template>
	<v-navigation-drawer
		class="pa-4 pr-0 pl-0"
		v-model="menuMobileDrawer"
		width="600"
		max-width="100%"
		overlay-color="#000"
		temporary
		style="z-index: 100"
		:overlay-opacity="0.8"
		fixed
		app
	>
		<v-list-item class="px-2 pb-3">
			<div class="pl-4">
				<router-link :to="{ name: 'home' }">
					<Media class="shrink mr-sm-2 mr-10" :src="$srv('SiteLogo.logoNavbar')" width="200" />
				</router-link>
			</div>
			<v-spacer></v-spacer>
			<v-btn :ripple="false" plain text @click="menuMobileDrawer = false">
				<v-icon x-large>mdi-close</v-icon>
			</v-btn>
		</v-list-item>
		<v-divider class="mb-4"></v-divider>
		<div>
			<v-list>
				<v-list-item v-if="user" :to="{ name: 'user.account' }" @click="menuMobileDrawer = false">
					<v-list-item-icon>
						<v-icon color="gold"> mdi-account </v-icon>
					</v-list-item-icon>
					<v-list-item-title>{{ user.firstname }}</v-list-item-title>
				</v-list-item>
				<v-list-item v-else @click.stop="showLoginDrawer">
					<v-list-item-icon>
						<v-icon color="gold"> mdi-account </v-icon>
					</v-list-item-icon>
					<v-list-item-title> Acceder </v-list-item-title>
				</v-list-item>
				<v-list-item v-for="({ text, url }, i) of links" :key="i">
					<SafeLink :to="url">
						<v-list-item-title> {{ text }} </v-list-item-title>
					</SafeLink>
				</v-list-item>
			</v-list>
		</div>
	</v-navigation-drawer>
</template>

<style scoped>
::v-deep i {
	color: var(--v-primary-base) !important;
}
::v-deep .v-list-item .theme--light {
	padding: 0 !important;
}
</style>
