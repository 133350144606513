<script>
export default {
	computed: {
		isHome() {
			return this.$route.name === 'home'
		},
	},
}
</script>

<template>
	<v-app>
		<MemberDialogs />
		<DateDialog />
		<!-- <SystemBar /> -->
		<AppBar />
		<MobileMenuDrawer />
		<LoginDrawer />
		<CartDrawer />
		<v-main :class="{ 'pt-15': !isHome }">
			<router-view />
		</v-main>
		<Footer />
	</v-app>
</template>
<style scoped>
::v-deep .fields-white label {
	color: white !important;
}
::v-deep .fields-white i {
	color: #ccc !important;
}
::v-deep .fields-white .v-text-field > .v-input__control > .v-input__slot:before {
	border-color: white !important;
}
::v-deep .fields-white input[type='date'] {
	color: white !important;
}
::v-deep .fields-white ::-webkit-calendar-picker-indicator {
	filter: invert(1);
}
</style>
