<script>
export default {
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>
<template>
	<div>
		<!-- <Home-A-Header-2 class="mb-16" /> -->
		<Home-A-Header class="mb-16" />
		<Home-B-Featured />
		<Home-BaresDelMundo />
		<Home-C-OurProducts />
		<Home-D-TheWhiskery class="mt-16" />
		<Home-LatestPosts />
	</div>
</template>
