<template>
	<div class="gold" style="height: 40px; position: absolute" v-if="!closed && text">
		<Container class="d-flex justify-space-between align-center t3 full-height">
			<span class="t6"> {{ text }} </span>
			<v-btn icon @click="closed = true">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</Container>
	</div>
</template>

<script>
export default {
	data() {
		return {
			closed: false,
		}
	},
	computed: {
		text() {
			return this.$srv('SystemBar.text')
		},
	},
}
</script>

<style></style>
