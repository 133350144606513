<script>
export default {
	name: 'ProductCard',
	props: {
		product: Object,
		listId: String,
		listIndex: Number,
	},
	computed: {
		productRoute() {
			return {
				name: 'product',
				params: { urlName: this.product.urlName, id: this.product.id },
			}
		},
		mainVariant() {
			return this.product.variants.find((variant) => variant.main) || this.product.variants[0]
		},
		prevPrice() {
			return this.mainVariant.pvPrice.prevPrice
		},
		price() {
			return this.mainVariant.pvPrice.price
		},
		discountPct() {
			return this.mainVariant.pvPrice.discountPct
		},
		mainImage() {
			return this.product.images[0].square
		},
		secondImage() {
			return this.product.images[1]?.square
		},
		currency() {
			return this.mainVariant.pvPrice.priceConfig.currency.sign
		},
	},
	methods: {
		async cardClick() {
			this.$eventer.trigger('product:click', {
				product: this.product,
				variant: this.mainVariant,
				listId: this.listId,
				index: this.listIndex,
			})
		},
	},
}
</script>

<template>
	<v-card
		elevation="5"
		hover
		flat
		tile
		@click="cardClick"
		:to="productRoute"
		color="white"
		height="100%"
		class="d-flex flex-column justify-sapce-between"
	>
		<ProductWishlistToggler :productId="product.id" class="abs" />
		<slot name="images" v-bind="{ mainImage, secondImage }">
			<ProductCardImage :mainImage="mainImage" :secondImage="secondImage" />
		</slot>
		<div class="d-flex flex-column align-center justify-space-between px-2 pb-2 h100">
			<div class="pt-2 pb-5">
				<div class="gold--text text-uppercase t2 py-1 text-center">
					{{ product.name }}
				</div>
				<!-- <div class="text-capitalize t3 line-clamp-2 py-1 text-center text-color">
					{{ product.info.description }}
				</div> -->
			</div>
			<div class="py-1 d-flex flex-column align-center">
				<PriceLayout
					:prevPrice="prevPrice"
					:price="price"
					:discountPct="discountPct"
					:currency="currency"
					price-class="gold--text s5"
					discount-class="gold white--text font-weight-bold pa-2"
					prev-price-class="mr-3 grey--text font-weight-bold"
					asboluteDiscount
					class="d-flex"
				/>
				<v-card-actions class="d-flex">
					<ProductActions :product="product" :variant="mainVariant" cta-text="Agregar" />
					<span class="gray--text font-weight-bold t3 ml-4">
						Ver
						<v-icon>mdi-chevron-right</v-icon>
					</span>
				</v-card-actions>
			</div>
		</div>
	</v-card>
</template>
<style scoped>
.abs {
	position: absolute;
	top: 5px;
	left: 5px;
	z-index: 1;
}
.text-color {
	color: rgb(156, 156, 156);
}
</style>
