<script>
export default {
	name: 'PriceLayout',
	props: {
		prevPrice: Number,
		price: Number,
		discountPct: Number,
		hidePrevPrice: Boolean,
		hideDiscount: Boolean,
		priceClass: String,
		discountClass: String,
		prevPriceClass: String,
		currency: String,
		asboluteDiscount: Boolean,
		asboluteDiscountLeft: Boolean,
	},
	computed: {
		hasPrevPrice() {
			return this.prevPrice && this.prevPrice > this.price
		},
		hasDiscountPct() {
			return this.discountPct > 0
		},
	},
}
</script>

<template>
	<div>
		<div v-if="!hidePrevPrice && hasPrevPrice" class="text-center text-md-start">
			<ProductPrevPrice :amount="prevPrice" :customClass="prevPriceClass" :currency="currency" />
		</div>
		<div class="d-flex align-center justify-center justify-md-start">
			<ProductPrice :amount="price" :customClass="priceClass" :currency="currency" />
			<ProductDiscount
				v-if="!hideDiscount && hasDiscountPct"
				:amount="discountPct"
				class="ml-2"
				:class="{ 'abs-right': asboluteDiscount, 'abs-left': asboluteDiscountLeft }"
				:customClass="discountClass"
			/>
		</div>
	</div>
</template>

<style scoped>
.abs-right {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1;
}
.abs-left {
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 1;
}
</style>
