<template>
	<v-dialog fullscreen class="rounded-0" v-model="dialog">
		<v-card
			color="darkgray"
			width="100%"
			class="fields-white white--text d-flex text-center text-uppercase flex-column justify-center align-center"
			tile
		>
			<div class="mb-4 mb-md-14">
				<Media src="../assets/isologo.svg"></Media>
			</div>
			<div class="ff-2-regular font-4 font-md-10 mb-8 px-4">
				Para acceder al website de <br />
				Whisky Boutique hay que ser <br />
				<span class="gold--text">mayor de edad.</span>
			</div>
			<div class="ff-1-regular font-2 px-4">
				Es parte de nuestro compromiso <br />
				con un consumo responsable de alcohol.
				<div class="mt-4 mb-10">Por favor, confirmá que tenés más de 18 años.</div>
				<div class="d-flex justify-center align-center flex-column">
					<WButton @click="enterTheWebsite">CONFIRMO QUE SOY MAYOR DE EDAD</WButton>
				</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import { get } from 'vuex-pathify'
export default {
	data: () => ({
		dialog: false,
		date: '',
	}),
	name: 'App',
	computed: {
		dateSaved: get('whisky/dateSaved'),
	},
	methods: {
		enterTheWebsite() {
			this.dialog = false
			this.$store.set('whisky/dateSaved', true)
		},
	},
	created() {
		if (this.$isBotAgent) return
		setTimeout(() => {
			if (this.dateSaved) this.dialog = false
			else this.dialog = true
		}, 2000)
	},
}
</script>

<style></style>
