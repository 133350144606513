<template>
	<div class="text-uppercase">
		<SectionTitle :title="$srv('Home_BaresDelMundo.title')" />
		<div class="background-image py-16 relative d-flex flex-column justify-center align-center">
			<div class="background-filter"></div>
			<div class="d-flex flex-column black--text relative">
				<div class="font">BARES</div>
				<div class="d-flex align-center">
					<span
						class="del"
						:class="{ 'mr-8': $vuetify.breakpoint.smAndDown, 'mr-16': !$vuetify.breakpoint.smAndDown }"
						>DEL</span
					>
					<span class="font">MUNDO</span>
				</div>
			</div>
			<v-btn
				large
				class="mt-16 rounded-0 elevation-0 btn-with-line"
				color="black"
				outlined
				@click="$emit('click:action-btn')"
				to="/sobre-el-whisky"
			>
				{{ $srv('Home_BaresDelMundo.btnText') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.background-image {
	background: url('../assets/BaresDelMundoBackground.png');
	background-size: 130%;
	background-position: center;
}
.del {
	font-size: 35pt;
	font-family: 'LustPro';
	@media screen and (max-width: 960px) {
		font-size: 17.5pt;
	}
	@media screen and (max-width: 500px) {
		font-size: 15pt;
	}
}
.background-filter {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: rgba(255, 255, 255, 0.4);
}
.font {
	font-family: 'LustPro';
	font-size: 100pt;
	line-height: 105pt;
	@media screen and (max-width: 960px) {
		font-size: 50pt;
		line-height: 60pt;
	}
	@media screen and (max-width: 500px) {
		font-size: 40pt;
		line-height: 45pt;
	}
}
.spacer {
	width: 400px;
	height: 5px;
}
</style>
