var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.length)?_c('section',{staticClass:"pb-16 coral w100"},[_c('SectionTitle',{attrs:{"title":"novedades - nuestro blog"}}),_c('Container',{attrs:{"width":"100%","max-width":"1200"}},[_c('Carousel',{staticClass:"w100",attrs:{"items":_vm.data,"options":{
				itemsPadding: _vm.$vuetify.breakpoint.smAndDown ? 3 : 8,
				panelsPerView: { xs: 1.5, sm: 3, md: 3 },
				arrows: { md: true },
				arrowsMargin: 30,
				itemWrapperClass: 'py-10',
			},"flicking-options":{
				align: 'prev',
				bound: true,
				moveType: { xs: 'freeScroll', md: 'snap' },
			}},scopedSlots:_vm._u([{key:"panel",fn:function(ref){
			var item = ref.item;
return [_c('safe-link',{attrs:{"to":item.link}},[_c('v-card',{attrs:{"hover":"","flat":"","tile":"","color":"transparent"}},[_c('Media',{attrs:{"height":_vm.$vuetify.breakpoint.smAndDown ? '250px' : '400px',"src":item.featured_image_url}}),_c('div',{staticClass:"black--text text-uppercase font-weight-bold t4 pt-3 pb-2 border"},[_c('div',{staticClass:"line-clamp-2",staticStyle:{"height":"40px"}},[_vm._v(" "+_vm._s(item.title.rendered)+" ")])]),_c('div',{staticClass:"py-2 d-flex justify-end black--text text-uppercase font-weight-bold t4"},[_vm._v(" Ver nota ")])],1)],1)]}}],null,false,3055882510)})],1),_c('div',{staticClass:"d-flex justify-center"},[_c('safe-link',{attrs:{"to":"https://blog.whiskyboutique.com.ar/"}},[_c('v-btn',{staticClass:"rounded-0 elevation-0 btn-with-line",attrs:{"large":"","color":"black","outlined":""}},[_vm._v(" Explorar ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }