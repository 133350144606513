<script>
import fiscalImage from './asset_fiscal.png'
import { get } from 'vuex-pathify'
export default {
	name: 'Footer',
	data() {
		return {
			fiscalImage,
			businessInfo: this.$srv('BusinessInfo', {}),
		}
	},
	methods: {
		memberBtnClicked() {
			this.$store.set('whisky/memberDialog', true)
		},
	},
	computed: {
		links() {
			const links = this.$srv('NavbarLinks').map((info) => {
				if (info.memberText && this.isMember)
					return {
						text: info.memberText,
						url: info.url,
					}
				else return info
			})
			return links
		},
		isMember: get('shop/user@member'),
	},
}
</script>

<template>
	<v-footer class="darkgreen white--text text-center">
		<Container class="py-16">
			<v-row class="pa-0">
				<v-col
					cols="12"
					md="4"
					class="text-center text-md-left d-flex flex-column justify-center align-center d-md-block mb-8 mb-md-0"
				>
					<div class="mb-4 t1">ACCEDÉ A DESCUENTOS Y PROMOCIONES EXCLUSIVO PARA MIEMBROS</div>
					<div class="mb-6 t4">
						Completando el formulario y dejando tus datos, ya te hacés parte de nuestra comunidad y
						podés empezar a disfrutar de todos nuestros beneficios.
					</div>
					<div class="mb-6">BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS</div>
					<div>
						<WButton
							large
							class="rounded-0 elevation-0 btn-with-line"
							dark
							outlined
							@click="memberBtnClicked"
							v-if="!isMember"
						>
							QUIERO SER MIEMBRO
						</WButton>
					</div>
				</v-col>
				<v-col cols="12" md="4" class="d-flex flex-column justify-center align-center">
					<div>
						<Media :src="$srv('SiteLogo.logoWhite')" width="230" height="170"></Media>
					</div>
					<div class="mt-6" v-if="businessInfo">
						<a
							v-if="businessInfo.instagram"
							:href="businessInfo.instagram"
							target="_blank"
							rel="noopener noreferrer"
						>
							<v-btn fab small class="mr-2" color="secondary">
								<v-icon> mdi-instagram </v-icon>
							</v-btn>
						</a>
						<a
							v-if="businessInfo.facebook"
							:href="businessInfo.facebook"
							target="_blank"
							rel="noopener noreferrer"
						>
							<v-btn fab small class="mr-2" color="secondary">
								<v-icon> mdi-facebook </v-icon>
							</v-btn>
						</a>
						<a
							v-if="businessInfo.youtube"
							:href="businessInfo.youtube"
							target="_blank"
							rel="noopener noreferrer"
						>
							<v-btn fab small class="mr-2" color="secondary">
								<v-icon> mdi-youtube </v-icon>
							</v-btn>
						</a>
					</div>
				</v-col>
				<v-col cols="12" md="4" class="mt-8 mt-md-0">
					<v-row>
						<v-col cols="12" md="5" class="d-flex justify-center justify-md-start align-center">
							<div>
								<span class="t1 mb-3">SECCIONES</span>
								<ul class="pa-0">
									<li
										style="list-style: none"
										class="t4 mt-1"
										v-for="({ text, url }, i) of links"
										:key="i"
									>
										<SafeLink :to="url" class="white--text hover-gold"> {{ text }}</SafeLink>
									</li>
									<li style="list-style: none" class="t4 mt-1">
										<safe-link
											to="https://blog.whiskyboutique.com.ar/"
											class="white--text hover-gold"
										>
											Blog
										</safe-link>
									</li>
								</ul>
							</div>
						</v-col>
						<v-col
							cols="12"
							md="7"
							class="d-flex flex-column justify-center justify-md-start align-center"
						>
							<div>
								<div class="t1">ESCRIBINOS</div>
								<div class="font-small mb-6" style="word-break: break-all">
									<a href="mailto:contacto@whiskyboutique.com.ar" class="hover-gold white--text">
										CONTACTO@WHISKYBOUTIQUE.COM.AR
									</a>
								</div>
								<div class="t1">INSTITUCIONAL</div>
								<div class="mb-2">
									<router-link to="/terminos-y-condiciones" class="white--text hover-gold">
										Términos y Condiciones.
									</router-link>
								</div>
								<div class="mb-2">
									<router-link to="/cambios-y-devoluciones" class="white--text hover-gold"
										>Políticas de Envíos, Cambios y Devoluciones
									</router-link>
								</div>
								<div class="mb-2">
									<router-link to="/privacidad" class="white--text hover-gold">
										Políticas de Privacidad
									</router-link>
								</div>
								<div class="d-flex justify-center align-center" v-if="businessInfo.afipLink">
									<a :href="businessInfo.afipLink" target="_blank" rel="noopener noreferrer">
										<Media :src="fiscalImage" width="70" contain> </Media>
									</a>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</Container>
		<Container class="py-2 d-flex justify-space-between">
			<div>Todos los derechos reservados &copy; Whisky Boutique</div>
			<div>
				Desarrollado por
				<a
					href="https://drubbit.com/"
					target="_blank"
					class="text-decoration-underline white--text hover-gold"
				>
					Drubbit
				</a>
			</div>
		</Container>
	</v-footer>
</template>

<style scoped>
.font-small {
	font-size: 11px;
}
.link-item {
	padding: 6px 0;
	color: white;
}
a.link-item {
	display: block;
	text-decoration: none;
}
.link-item >>> .v-icon {
	margin-right: 4px;
	color: white;
}
.hover-gold {
	transition: color 0.2s;
}
.hover-gold:hover {
	color: var(--v-gold-base) !important;
}
</style>
