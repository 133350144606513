<template>
	<section v-if="data.length" class="pb-16 coral w100">
		<SectionTitle title="novedades - nuestro blog" />

		<Container width="100%" max-width="1200">
			<Carousel
				class="w100"
				:items="data"
				:options="{
					itemsPadding: $vuetify.breakpoint.smAndDown ? 3 : 8,
					panelsPerView: { xs: 1.5, sm: 3, md: 3 },
					arrows: { md: true },
					arrowsMargin: 30,
					itemWrapperClass: 'py-10',
				}"
				:flicking-options="{
					align: 'prev',
					bound: true,
					moveType: { xs: 'freeScroll', md: 'snap' },
				}"
			>
				<template #panel="{ item }">
					<safe-link :to="item.link">
						<v-card hover flat tile color="transparent">
							<Media
								:height="$vuetify.breakpoint.smAndDown ? '250px' : '400px'"
								:src="item.featured_image_url"
							>
							</Media>
							<div class="black--text text-uppercase font-weight-bold t4 pt-3 pb-2 border">
								<div style="height: 40px" class="line-clamp-2">
									{{ item.title.rendered }}
								</div>
							</div>
							<div class="py-2 d-flex justify-end black--text text-uppercase font-weight-bold t4">
								Ver nota
							</div>
						</v-card>
					</safe-link>
				</template>
			</Carousel>
		</Container>
		<div class="d-flex justify-center">
			<safe-link to="https://blog.whiskyboutique.com.ar/">
				<v-btn large class="rounded-0 elevation-0 btn-with-line" color="black" outlined> Explorar </v-btn>
			</safe-link>
		</div>
	</section>
</template>

<script>
const apiUrl = 'https://blog.whiskyboutique.com.ar//wp-json/wp/v2/posts'
export default {
	data: () => ({
		data: [],
		modalActive: false,
		blogData: undefined,
	}),
	created() {
		this.getData()
	},
	methods: {
		getData() {
			fetch(apiUrl)
				.then((res) => res.json())
				.then((json) => {
					this.data = json
				})
		},
		activateModal(item, i) {
			this.blogData = {
				...item,
				index: i,
				prev: i === 0 ? null : this.data[i - 1].title.rendered,
				next: i === this.data.length - 1 ? null : this.data[i + 1].title.rendered,
			}
			this.modalActive = true
		},
		prevBlog() {
			let index = this.blogData.index
			this.activateModal(this.data[index - 1], index - 1)
		},
		nextBlog() {
			let index = this.blogData.index
			this.activateModal(this.data[index + 1], index + 1)
		},
	},
}
</script>

<style>
.border {
	border-bottom: 1px solid var(--v-gray-base);
}
</style>
