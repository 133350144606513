var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data && _vm.data.products.length)?_c('section',{staticClass:"coral w100"},[_c('SectionTitle',{attrs:{"title":_vm.data.title}}),_c('Container',{attrs:{"width":"100%","max-width":"1200"}},[_c('Carousel',{staticClass:"product-card-carousel w100",attrs:{"items":_vm.data.products,"options":{
				itemsPadding: _vm.$vuetify.breakpoint.smAndDown ? 3 : 8,
				panelsPerView: { xs: 1.5, sm: 3, md: 3 },
				arrows: { md: true },
				arrowsMargin: 30,
				itemWrapperClass: 'py-10',
			},"flicking-options":{
				align: 'prev',
				bound: true,
				moveType: { xs: 'freeScroll', md: 'snap' },
			}},scopedSlots:_vm._u([{key:"panel",fn:function(ref){
			var item = ref.item;
			var i = ref.i;
return [_c('HomeProductCard',{attrs:{"product":item,"list-index":i,"list-id":"home-b-featured"}})]}}],null,false,1522345249)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }