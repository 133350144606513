<template>
	<div>
		<form
			class="d-none d-md-flex mr-4 pb-1"
			:style="`border-bottom: 1px solid ${dark ? 'white' : 'black'}`"
			@submit.prevent="search($refs.input.value)"
		>
			<div>
				<v-icon small :dark="dark" class="mt-1"> mdi-magnify </v-icon>
			</div>
			<input
				type="search"
				ref="input"
				class="pl-2"
				placeholder="BUSCAR"
				:style="{ color: dark ? 'white' : 'black' }"
			/>
		</form>
		<div class="d-md-none">
			<MobileSearch :dark="dark" @search="search" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		dark: Boolean,
	},
	methods: {
		search(text) {
			this.dialog = false
			text = text ? text.trim() : ''
			this.$shop.search(text)
		},
	},
}
</script>

<style scoped lang="scss">
input {
	width: 130px;
	height: 25px;
	&:focus {
		outline: none;
	}
	&::placeholder {
		font-family: 'Roobert Medium';
		letter-spacing: 1px;
		font-size: 12px;
	}
}
</style>
