<script>
export default {
	name: 'ProductsLayout',
	props: {
		products: Array,
		loading: Boolean,
		horizontal: Boolean,
		listId: String,
		cardsPerLine: {
			type: Number,
			default: 3,
		},
		triggerListView: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			page: 1,
		}
	},
	computed: {
		colSize() {
			switch (this.cardsPerLine) {
				case 2:
					return 6
				case 3:
					return 4
				case 4:
					return 3
				case 6:
					return 2
			}
			return 4
		},
	},
	mounted() {
		if (this.products.length && this.listId && this.triggerListView) {
			this.$eventer.trigger('product-list:view', {
				products: this.products,
				listId: this.listId,
			})
		}
	},
}
</script>

<template>
	<v-row v-if="loading">
		<v-col cols="12" :sm="colSize" class="mb-10" v-for="i of Array(12 / colSize).keys()" :key="i">
			<v-skeleton-loader type="card,paragraph" loading />
		</v-col>
	</v-row>
	<v-row v-else>
		<v-col
			cols="12"
			:sm="horizontal ? 12 : 6"
			:lg="horizontal ? 6 : 4"
			class="mb-5"
			v-for="(product, i) in products"
			:key="product.id"
		>
			<WideProductCard :product="product" v-if="horizontal" :list-id="listId" :list-index="i" />
			<ProductCard :product="product" :list-id="listId" :list-index="i" v-else />
		</v-col>
	</v-row>
</template>
