<script>
import { get } from 'vuex-pathify'

export default {
	name: 'AppBar',
	props: {
		showMenu: Boolean,
	},
	data() {
		return {
			searchText: null,
			dialog: false,
			fixed: false,
			dark: true,
		}
	},
	computed: {
		user: get('shop/user'),
		routeSearch: get('route@query.search'),
		order: get('cart/order'),
		orderItemsQty() {
			return this.order?.items.reduce((sum, item) => sum + item.qty, 0)
		},
		isHome() {
			return this.$route.name === 'home'
		},
	},
	watch: {
		routeSearch(value) {
			this.searchText = value
		},
		$route() {
			this.checkDark()
		},
	},
	methods: {
		showLoginDrawer() {
			this.$store.set('shop/loginDrawer', true)
		},
		showCartDrawer() {
			this.$store.set('shop/cartDrawer', true)
		},
		showMobileDrawer() {
			this.$store.set('shop/menuMobileDrawer', true)
		},
		search() {
			this.dialog = false
			let txt = this.searchText ? this.searchText.trim() : ''
			if (txt.length <= 2) return
			this.$shop.search(txt)
		},
		checkDark() {
			this.dark = this.isHome && window.scrollY <= window.innerHeight * 0.89
		},
	},
	mounted() {
		this.checkDark()
		window.addEventListener('scroll', () => this.checkDark())
	},
}
</script>

<template>
	<div
		class="fixed"
		:style="{ height: dark ? '100px' : '60px', transition: 'all .4s' }"
		:class="{ 'white--text': dark, white: !dark }"
	>
		<div :style="{ 'border-bottom': !dark ? '1px solid #808080' : '' }" class="h100 px-2">
			<Container class="d-flex justify-space-between align-center h100 ma-auto pa-0">
				<div class="d-md-none">
					<v-btn icon :dark="dark" @click="showMobileDrawer">
						<v-icon>mdi-menu</v-icon>
					</v-btn>
				</div>
				<div>
					<LogoAppbar :dark="dark" />
				</div>
				<div class="d-none d-md-flex h100">
					<AppbarNavLinks />
				</div>
				<div class="d-flex">
					<div>
						<SearchInput :dark="dark" />
					</div>
					<div class="d-none d-md-flex align-center">
						<v-btn text @click="showLoginDrawer" v-if="!user" :dark="dark" small>
							<v-icon left>mdi-account-circle-outline</v-icon>
							<span class="text-uppercase link-font">Iniciar Sesion</span>
						</v-btn>
						<v-btn v-else text :to="{ name: 'user.account' }" :dark="dark">
							<v-icon left>mdi-account-circle-outline</v-icon>
							<span class="text-uppercase link-font">{{ user.firstname }} {{ user.lastname }} </span>
						</v-btn>
					</div>
					<div>
						<v-btn :dark="dark" icon @click="showCartDrawer">
							<v-icon>mdi-cart-outline</v-icon>
						</v-btn>
					</div>
				</div>
			</Container>
		</div>
	</div>
</template>

<style scoped lang="scss">
.border-bottom-gold {
	border-bottom: 3px solid var(--v-gold-base) !important;
	box-sizing: content-box;
}
.fixed {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 10;
}
.menu-item {
	position: relative;
}
.menu-item::before {
	content: '';
	background-color: #aaa;
	position: absolute;
	width: 0;
	height: 2px;
	top: 100%;
	left: 0;
	opacity: 0;
	transition: all 0.5s;
}
.menu-item.router-link-active::before,
.menu-item:hover::before {
	width: 100%;
	opacity: 1;
}
.menu-item.router-link-active::before {
	height: 6px;
	background-color: #000;
}
</style>
