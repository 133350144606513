<script>
import facebook from './assets/facebook.svg'
import instagram from './assets/instagram.svg'
import whatsapp from './assets/whatsapp.svg'
import link from './assets/link.svg'

export default {
	data() {
		return { facebook, instagram, whatsapp, link, snackbar: false }
	},
	props: {
		url: String,
	},
	computed: {
		fbUrl() {
			return `http://www.facebook.com/sharer.php?u=${document.URL}&t=Compartir_producto`
		},
		wppUrl() {
			let ulrEncoded = encodeURIComponent(document.URL)
			return `https://wa.me/?text=${ulrEncoded}`
		},
		igUrl() {
			return this.$srv('BusinessInfo', {}).instagram
		},
	},
	methods: {
		copyUrl() {
			navigator.clipboard.writeText(document.URL)
			this.snackbar = true
		},
	},
}
</script>
<template>
	<div class="d-flex align-center justify-center py-3">
		<span class="text-uppercase font-weight-bold t5 mr-5">Compartir</span>
		<div class="d-flex">
			<SafeLink :to="fbUrl">
				<Media :src="facebook" width=".6rem" class="mx-2" contain />
			</SafeLink>
			<SafeLink :to="igUrl" v-if="igUrl">
				<Media :src="instagram" width="1rem" class="mx-2" contain />
			</SafeLink>
			<SafeLink :to="wppUrl">
				<Media :src="whatsapp" width="1rem" class="mx-2" contain />
			</SafeLink>
			<Media :src="link" width="1rem" class="mx-2" contain @click="copyUrl" style="cursor: pointer" />
		</div>
		<v-snackbar v-model="snackbar">
			El enlace se copió al portapapeles
			<template v-slot:action="{ attrs }">
				<v-btn color="gold" icon v-bind="attrs" @click="snackbar = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
