<script>
import { get, sync } from 'vuex-pathify'
import logoSrc from '../assets/Group_1745.png'

export default {
	data() {
		return {
			memberFormDialog: false,
			alreadyMember: false,
			loginDialog: false,
			successDialog: false,
			logoSrc,
		}
	},
	computed: {
		routeQuery: get('route@query'),
		memberDialog: sync('whisky/memberDialog'),
		user: get('shop/user'),
		isMember() {
			return !!this.user?.member
		},
		isSuccess() {
			return this.routeQuery['member-form-success'] === null
		},
		isMemberLogout() {
			return this.routeQuery['member-logout'] === null
		},
		isCheckout() {
			return this.$route.name?.startsWith('checkout')
		},
	},
	methods: {
		openMemberFormDialog() {
			if (this.user) {
				if (this.user.member) this.openAlreadyMemberDialog()
				else this.memberFormDialog = true
			} else this.openLoginDialog()
		},
		openAlreadyMemberDialog() {
			this.alreadyMember = true
		},
		openSuccessDialog() {
			this.successDialog = true
		},
		openLoginDialog() {
			this.loginDialog = true
		},
		onCheckboxChange(v) {
			this.$store.set('whisky/dontShowMemberDialog', v)
		},
	},
	mounted() {
		if (this.$isBotAgent) return
		const dontShowMemberDialog = this.$store.get('whisky/dontShowMemberDialog')
		if (this.isSuccess) this.openSuccessDialog()
		if (!this.isMember && !this.isMemberLogout && !dontShowMemberDialog) {
			this.memberDialog = true
		}
	},
	watch: {
		user(user) {
			if (user && !user.member) {
				if (this.memberDialog) this.openMemberFormDialog()
				else this.memberDialog = true
			}
		},
		isSuccess(v) {
			if (v) this.openSuccessDialog()
		},
	},
}
</script>

<template>
	<div>
		<v-dialog
			v-model="memberDialog"
			max-width="800"
			class="rounded-0"
			overlay-color="#F5F5F5"
			persistent
			tile
		>
			<v-card
				height="550px"
				color="#1A3B34"
				tile
				style="color: #fff1d9"
				class="d-flex text-center text-uppercase flex-column justify-center justify-md-space-around align-center pa-4 relative"
			>
				<v-btn @click="memberDialog = false" class="close-btn" icon x-large>
					<v-icon color="white" x-large>mdi-close</v-icon>
				</v-btn>
				<div class="logo-background">
					<Media as="v-img" :src="logoSrc" />
				</div>
				<div class="ff-2-regular font-md-10 font-sm-8 font-5 pt-12 mb-8 mb-md-0">
					SE PARTE DE NUESTRA COMUNIDAD
				</div>
				<div class="ff-1-regular font-2 pa-md-0">
					convertite en miembro y <br />
					empezá a disfrutar de nuestros <br />
					beneficios exclusivos
					<div class="mt-8 d-flex flex-column justify-center align-center">
						<v-checkbox label="No volver a mostrar" dark @change="onCheckboxChange"></v-checkbox>
						<WButton x-large @click="openMemberFormDialog"> Continuar </WButton>
					</div>
				</div>
			</v-card>
		</v-dialog>
		<v-dialog v-model="memberFormDialog" max-width="290" fullscreen v-if="user && !isMember">
			<TW-Form @click:close="memberFormDialog = false" :opened="memberFormDialog" />
		</v-dialog>
		<v-dialog v-model="loginDialog" width="600" persistent v-if="!user">
			<v-card width="100%" class="pa-8">
				<v-btn icon @click="loginDialog = false" class="close-btn">
					<v-icon x-large color="gold"> mdi-close </v-icon>
				</v-btn>
				<LoginForms />
			</v-card>
		</v-dialog>
		<v-dialog v-model="alreadyMember" class="rounded-0" width="600" v-if="isMember">
			<v-card tile class="d-flex flex-column justify-center align-center pa-8">
				<div class="text-uppercase ff-1-medium font-4 mb-6 text-center">
					Como miembro, ya estás disfrutando de nuestros beneficios.
				</div>
				<WButton class="px-6" @click="alreadyMember = false" to="/shop"> Ingresá al shop </WButton>
			</v-card>
		</v-dialog>
		<v-dialog
			max-width="1000"
			class="rounded-0"
			overlay-color="#F5F5F5"
			:value="true"
			v-model="successDialog"
			v-if="isSuccess && isMember"
		>
			<v-card
				:color="$vuetify.breakpoint.smAndDown ? 'black' : '#F5F5F5'"
				:class="{ 'white--text pa-1': $vuetify.breakpoint.smAndDown }"
				tile
				height="550"
				class="d-flex"
			>
				<div class="h100" :class="{ 'success-img': $vuetify.breakpoint.smAndDown }">
					<Media src="../assets/Success.png" width="400" height="100%"></Media>
				</div>
				<div
					class="h100 d-flex flex-column justify-center align-center text-center flex-grow-1 relative py-8"
				>
					<div class="ff-2-regular font-8">
						BIENVENIDO/A <br />
						A NUESTRA <br />
						COMUNIDAD!
					</div>
					<!-- <div class="text-uppercase">
						Ya sos miembro y podés disfrutar de todos nuestros beneficios. <br />
						Aplicá el cupón "<b>SOYMIEMBRO</b>" para obtener <br />
						envío sin cargo en tu primera compra.
					</div>
					<div class="mb-6 mt-2 font-1 font-italic font-weight-medium">
						Cupón válido hasta el 25 de junio.
					</div> -->
					<div class="pt-12">
						<WButton large class="px-6" @click="successDialog = false" to="/shop" v-if="!isCheckout">
							Ingresá al shop
						</WButton>
						<WButton large class="px-6" @click="successDialog = false" v-else>
							Finalizar compra
						</WButton>
					</div>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<style scoped>
.relative {
	position: relative;
}
.success-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.4;
}
.logo-background {
	position: absolute;
	left: 50%;
	top: 11%;
	transform: translateX(-50%);
}
.close-btn {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1;
}
::v-deep .v-dialog {
	border-radius: 0 !important;
	word-spacing: 5px;
}
</style>
