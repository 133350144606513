<script>
export default {
	props: {
		text: Boolean,
	},
}
</script>

<template>
	<v-hover v-slot="{ hover }">
		<Button color="gold" :outlined="!hover && !text" :text="text" v-bind="$attrs" v-on="$listeners">
			<slot />
		</Button>
	</v-hover>
</template>

<style></style>
