import Vue from 'vue'
import router from '@/site/router'
import { shopApi } from './plugin_shop-api'
import store from '@/site/store'
import Srv from '@/__shared/utils/lib_srv'

const Shop = {}
Shop.search = async function (searchText) {
	searchText = searchText ? searchText.trim() : ''
	if (searchText.length <= 2) return
	shopApi.post(`/shop-event/search`, { data: { eventData: { input: searchText } } })
	return this.goShop({ search: searchText })
}

Shop.getShopRoute = function ({ collection, categories, brand, search } = {}) {
	let filters = []
	if (!collection && !categories) {
		filters.push(process.env.VUE_APP_SHOP_ALL_KEYWORD)
	} else {
		if (collection) {
			filters.push(collection)
		}
		if (categories) {
			filters = filters.concat(categories)
		}
	}
	if (brand) {
		filters.push(`brand-${brand}`)
	}

	let query = {}
	if (search) query.search = search

	return { name: 'shop', params: { filters }, query }
}

Shop.goShop = async (options = {}) => router.push(Shop.getShopRoute(options))

Shop.addToCart = async ({
	productId,
	variantId,
	type = 'product',
	customization = {},
	qty = 1,
	sum = true,
	showUpsells = false,
	showCart = true,
	...options
}) => {
	return shopApi.post({
		...options,
		url: '/cart/item',
		data: {
			qty,
			refType: type,
			refId: variantId,
			customization,
			sum,
		},
		onSuccess: async (apiRes) => {
			store.set('cart/order', apiRes.data.order)
			if (showUpsells) {
				let upsells = await Shop.getUpsells({ productId, loading: false })
				if (upsells?.length) {
					store.set('shop/upsells', upsells)
					apiRes.data.upsells = upsells
					await router.push({ name: 'upsells', params: { id: productId } })
				} else {
					showCart && Shop.showCart()
				}
			} else {
				showCart && Shop.showCart()
			}

			await options.onSuccess?.call(null, apiRes)
		},
	})
}

Shop.showCart = (b = true) => {
	store.set('shop/cartDrawer', b)
}

Shop.getUpsells = async ({ productId, ...options }) => {
	let { data, success } = await shopApi.get({
		...options,
		url: `/catalog/product-upsells/${productId}`,
	})
	return success && data.upsellsProducts
}

Shop.changeLang = (langIso) => {
	if (langIso == Srv('lang.iso')) return
	window.location.href = router.resolve({ query: { lang: langIso } }).href
}

Vue.use({
	install: (Vue) => {
		Vue.prototype.$shop = Shop
	},
})
