<template>
	<nav class="h100 d-flex">
		<router-link
			v-for="({ text, url }, i) of links"
			:key="i"
			:to="url"
			class="nav-link text-uppercase px-md-4 h100 d-flex align-center justify-center text-center"
			active-class="active"
		>
			{{ text }}
		</router-link>
	</nav>
</template>

<script>
export default {
	computed: {
		links() {
			return this.$srv('NavbarLinks').map((info) => {
				if (info.memberText && !!this.user?.member)
					return {
						text: info.memberText,
						url: info.url,
					}
				else return info
			})
		},
	},
}
</script>

<style scoped lang="scss">
a {
	color: unset;
}
.nav-link {
	font-size: 12px;
	color: unset !important;
	// color: white;
	&.active {
		border-bottom: 3px solid var(--v-gold-base) !important;
	}
}
</style>
