<template>
	<section v-if="d">
		<SectionTitle :title="d.title" class="mb-16" />
		<Container class="pa-0 d-flex flex-column align-center" width="100%" max-width="1000px">
			<SafeLink :to="d.first_url" class="mb-16" v-if="d.show_first_product">
				<div>
					<div class="title-font text-uppercase mb-12 d-flex align-center">
						<v-icon color="black" class="mr-2"> mdi-arrow-right</v-icon>
						{{ d.first_title }}
					</div>
					<Media :src="d.first_image" max-width="80vw"></Media>
				</div>
			</SafeLink>
			<SafeLink :to="d.second_url" class="w100 mt-16 pa-4" v-if="d.show_second_product">
				<div class="d-flex justify-center align-center">
					<div :class="{ 'pr-16': !smAndDown }">
						<Media max-width="50vw" :src="d.second_image"></Media>
					</div>
					<div class="d-flex" :class="{ 'pl-16': !smAndDown, 'pl-6 pr-2': smAndDown }">
						<div class="pt-1">
							<v-icon color="black" class="mr-2"> mdi-arrow-right</v-icon>
						</div>
						<div class="title-font text-uppercase" style="max-width: 200px">
							{{ d.second_title }}
						</div>
					</div>
				</div>
			</SafeLink>
			<SafeLink :to="d.third_url" class="w100 mt-16 pa-4" v-if="d.show_third_product">
				<div class="w100 d-flex justify-end align-center">
					<div
						class="title-font text-uppercase d-flex align-start"
						style="max-width: 200px"
						:class="{ 'pr-16': !smAndDown, 'pr-6 pl-2': smAndDown }"
					>
						<div>
							{{ d.third_title }}
						</div>
						<div>
							<v-icon color="black"> mdi-arrow-left</v-icon>
						</div>
					</div>
					<div :class="{ 'pl-16': !smAndDown }">
						<Media max-width="50vw" :src="d.third_image"></Media>
					</div>
				</div>
			</SafeLink>
			<SafeLink :to="d.fourth_url" class="w100 mt-16 pa-4" v-if="d.show_fourth_product">
				<div class="d-flex w100 justify-start align-center">
					<div :class="{ 'pr-16': !smAndDown, 'pr-6': smAndDown }">
						<Media max-width="50vw" :src="d.fourth_image"></Media>
					</div>
					<div class="title-font text-uppercase" :class="{ 'pl-16': !smAndDown, 'pl-2': smAndDown }">
						<v-icon color="black" class="mr-2"> mdi-arrow-right</v-icon>
						{{ d.fourth_title }}
					</div>
				</div>
			</SafeLink>
			<SafeLink :to="d.fifth_url" class="w100 mt-16 pa-4" v-if="d.show_fifth_product">
				<div class="w100 d-flex justify-end align-center">
					<div class="title-font text-uppercase" :class="{ 'pr-16': !smAndDown, 'pr-2': smAndDown }">
						{{ d.fifth_title }}
						<v-icon color="black" class="ml-2"> mdi-arrow-left</v-icon>
					</div>
					<div :class="{ 'pl-16': !smAndDown, 'pl-6': smAndDown }">
						<Media max-width="50vw" :src="d.fifth_image"></Media>
					</div>
				</div>
			</SafeLink>
			<SafeLink :to="d.sixth_url" class="w100 mt-16 pa-4" v-if="d.show_sixth_product">
				<div class="w100 d-flex justify-start align-center">
					<div :class="{ 'pr-16 mr-16': !smAndDown }">
						<Media max-width="50vw" :src="d.sixth_image"></Media>
					</div>
					<div
						class="title-font text-uppercase"
						:class="{ 'pl-16': !smAndDown, 'pl-6 pr-2': smAndDown }"
					>
						<v-icon color="black" class="mr-2"> mdi-arrow-right</v-icon>
						{{ d.sixth_title }}
					</div>
				</div>
			</SafeLink>
		</Container>
	</section>
</template>

<script>
export default {
	computed: {
		d() {
			return this.$srv('Home_C_OurProducts')
		},

		smAndDown() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<style lang="scss" scoped>
.v-application a {
	color: black !important;
}
.title-font {
	font-family: 'Roobert Medium';
	font-size: 26px;
	@media screen and (max-width: 960px) {
		font-size: 1.2rem;
	}
	cursor: pointer;
	&:hover {
		color: var(--gold) !important;
	}
}
</style>
