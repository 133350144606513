<template>
	<div>
		<v-btn icon :dark="dark" @click="show = true">
			<v-icon>mdi-magnify</v-icon>
		</v-btn>
		<transition name="slide-fade">
			<div v-if="show" class="search-container d-flex align-center justify-center">
				<div class="search-backdrop"></div>
				<div class="px-4">
					<v-btn icon dark @click="show = false" large>
						<v-icon x-large>mdi-close</v-icon>
					</v-btn>
				</div>
				<form class="search-field w100 d-flex flex-nowrap pr-4" @submit.prevent="search">
					<input
						ref="input"
						type="search"
						name="search"
						id="search"
						placeholder="Buscar"
						class="white w100 h100 pl-4"
					/>
					<v-btn color="gold btn-magnify" tile type="submit">
						<v-icon color="white">mdi-magnify</v-icon>
					</v-btn>
				</form>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	props: {
		dark: Boolean,
	},
	data() {
		return {
			show: false,
		}
	},
	watch: {
		show(v) {
			if (v) {
				this.$nextTick(() => {
					this.$refs.input.focus()
				})
			}
		},
	},
	methods: {
		search() {
			this.$emit('search', this.$refs.input.value)
		},
	},
}
</script>

<style lang="scss">
.search-container {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 80px;
	z-index: 2;
}
.search-backdrop {
	// filter: blur(4px);
	background-color: rgba(0, 0, 0, 1);
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	right: 0;
}
.search-field {
	position: relative;
	height: 55px;
	& .btn-magnify {
		height: 100% !important;
	}
	input:focus {
		outline: none;
	}
}
.slide-fade-enter-active,
.slide-fade-leave-active {
	transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
	transform: translateY(-100%);
}
.slide-fade-enter-to,
.slide-fade-leave {
	transform: translateY(0%);
}
</style>
