import store from '@/site/store'

let _user = store.get('shop/user')
let _isMember = !!_user?.member
import router from '@/site/router'

store.watch(
	() => store.get('shop/user'),
	(user) => {
		let isMember = !!user?.member
		if (isMember != _isMember) {
			let key = user ? 'member-login' : 'member-logout'

			window.location.href = router.resolve({
				query: {
					...router.currentRoute.query,
					[key]: null,
				},
			}).href
		}
	}
)
